
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonSpinner,

  onIonViewDidEnter,
  isPlatform,
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/store';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem'
import { isApiError, UserPhoto } from '@/lib/api-endpoints'
import { createHash } from 'crypto-browserify'


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonBackButton,
    IonIcon,
    IonButton,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    IonSpinner,
  },
  setup(_props){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const newPhoto = ref<UserPhoto>();
    const processing = ref(false);
    const confirmDelete = ref(false);

    onIonViewDidEnter(() =>{
      processing.value = false;
      newPhoto.value = undefined;
      confirmDelete.value = false;
    });

    const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
          resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

    // promise based adapter to load an image.
    const loadImage = (url: string) => new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (err) => reject(err));
      img.src = url;
    });

    const processPicture = async (photo: Photo): Promise<UserPhoto> => {
      console.log(photo);
      let base64Data: string;
      // "hybrid" will detect mobile - iOS or Android
      if (isPlatform('hybrid')) {
        const file = await Filesystem.readFile({
          path: photo.path!
        });
        base64Data = `data:image/${photo.format};base64,${file.data}`;
      } else {
        // Fetch the photo, read as a blob, then convert to base64 format
        const response = await fetch(photo.webPath!);
        const blob = await response.blob();
        base64Data = await convertBlobToBase64(blob) as string;
      }

      // resize the image based on its largest dimension
      //const img = new Image();
      const img = await loadImage(base64Data) as HTMLImageElement;
      const canvas = document.createElement("canvas");
      canvas.width = 1080;
      canvas.height = 1920;
      const ctx = canvas.getContext("2d");
      if (ctx){
        // select the smallest relative dimension, and resize that to fit into 1920x1080
        let 
          sourceX: number,
          sourceY: number, 
          sourceWidth: number, 
          sourceHeight: number;
        const targetRatio = (16.0/9.0);

        if ((img.width * targetRatio) < img.height){
          // width is deciding dimension
          sourceWidth = img.width;
          sourceX = 0;
          sourceHeight = img.width * targetRatio;
          sourceY = (img.height - sourceHeight) / 2.0;
        } else {
          // height is deciding dimension
          sourceHeight = img.height;
          sourceY = 0;
          sourceWidth = img.height / targetRatio;
          sourceX = (img.width - sourceWidth) / 2.0; 
        }

        // Actual resizing
        ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, 1080, 1920);
        //ctx.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, sourceWidth, sourceHeight);
        base64Data = canvas.toDataURL("image/" + photo.format);

        const img2 = new Image();
        img2.src = base64Data;

      }

      const imgHash = createHash("md5").update(base64Data).digest("base64");
      const result: UserPhoto = {data: base64Data, hash:imgHash};
      return result;
    };

    const getPhoto = async (source: CameraSource) => {
      try{
        processing.value = true;
        const cameraPhoto = await Camera.getPhoto({
          resultType: CameraResultType.Uri,
          source: source,
          quality: 100
        });
        newPhoto.value = await processPicture(cameraPhoto);
      } catch (ex) {
        console.error(ex.message);
        newPhoto.value = undefined;
      } finally {
        processing.value = false;
      }
    };

    const takePhoto = async () => {
      return getPhoto(CameraSource.Camera);
    };

    const loadPhoto = async () => {
      return getPhoto(CameraSource.Photos);
    };

    async function onSubmit(){
      const result = await store.dispatch("saveBgImg", newPhoto.value);
      if (! isApiError(result)){
        router.push('/tabs/home');
      }
    }

    async function takePhotoClicked(){
      await takePhoto();
    }
    
    async function selectImageClicked(){
      await loadPhoto();
    }

    async function deleteImageClicked(){
      const result = await store.dispatch("saveBgImg", undefined);
      if (! isApiError(result)){
        router.push('/tabs/home');
      }
    }

    return {
      onSubmit,
      takePhotoClicked,
      selectImageClicked,
      deleteImageClicked,
      checkmark,
      route,
      newPhoto,
      processing,
      confirmDelete,
    }
  }
});
